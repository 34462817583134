import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { Container, Row, Col } from "../components/grid"
import { Content } from "./blog-detail"

const IndexPage = ({ pageContext: { langKey }, data: { mdx } }) => {
  return (
    <Layout langKey={langKey}>
      <SEO title={mdx.frontmatter.title} lang={langKey} />
      <Content>
        <MDXProvider components={{ Container, Row, Col }}>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </Content>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query($slug: String, $langKey: String) {
    mdx(fields: { langKey: { eq: $langKey }, slug: { eq: $slug } }) {
      body
      frontmatter {
        title
      }
    }
  }
`
